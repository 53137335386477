import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import Localized from "../Localized";
// Define the context
const LanguageContext = createContext<any>(null);

// Create a provider component
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { basicLanguage, token } = useAppSelector((state: RootState) => state.userSlice);

    const [language, setLanguage] = useState(basicLanguage || "ru");

    const switchLanguage = (lang: string) => {
        Localized.setLanguage(lang.toLowerCase());
        setLanguage(lang);
        localStorage.setItem("language", lang.toLowerCase());
    };

    useEffect(() => {
        if(basicLanguage && token) {
            switchLanguage(basicLanguage);
        }
        
    }, [basicLanguage]);

    return (
        <LanguageContext.Provider value={{ language, switchLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Custom hook to use the language context
export const useLanguage = () => {
    return useContext(LanguageContext);
};
