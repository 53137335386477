import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import axios from "axios";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./anim.json";
import ticketJump from "./ok.json";
import Localized from "../../../Localized";
import "./style.scss";
import { setStep } from "../../../redux/slices/windowSlice";
import { useLanguage } from "../../../contexts/LanguageContext";

const ActivateStep = () => {
    // ...existing code...
    const dispatch = useAppDispatch();
    const [baseURL, setBaseURL] = useState(null);
    const { switchLanguage } = useLanguage();
    const [searchParams] = useSearchParams();
    let order_id = searchParams.get("order_id");
    let token = searchParams.get("token");
    let hash = searchParams.get("hash");
    let mode = searchParams.get("mode");
    let lang = searchParams.get("base_lang");

    // Состояния для активации и страницы
    const [activationStatus, setActivationStatus] = useState("");
    const [orderData, setOrderData] = useState([]);
    const [pageTheme, setPageTheme] = useState(null);
    const [isActivating, setIsActivating] = useState(false);
    const [activated, setActivated] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    const handleTrouble = () => {
        const email = "support@ticketok.by";
        const subject = encodeURIComponent(`Проблема с заказом ID ${order_id}`);

        const orderItems = orderData
            .map(
                (item) =>
                    `Билет: ${item.category_title}\nQR-код: ${item.barcode}\nСерийный номер: ${item.serial_number}\n`
            )
            .join("\n");

        const bodyText = `Добрый день,\n\nвозникла проблема при активации билетов\n\nДанные заказа:\n${orderItems}\n\nОписание проблемы (напишите здесь):`;
        const body = encodeURIComponent(bodyText);

        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };

    useEffect(() => {
        if (mode === "dev") {
            setBaseURL(process.env.REACT_APP_QA__URL);
        } else {
            setBaseURL(process.env.REACT_APP_API_URL);
        }
        dispatch(setStep("activate"));
    }, [mode, dispatch]);

    useEffect(() => {
        if (lang) {
            switchLanguage(lang);
            Localized.setLanguage(lang);
        }
    }, []);

    useEffect(() => {
        const fetchTicket = () => {
            axios({
                method: "POST",
                url: `${baseURL}/order/need_activate`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    hash: hash,
                    order_id: order_id,
                },
            })
                .then(function (response) {
                    if (response.status === 200) {
                        const resStatus = response.data.status;
                        if (resStatus === "already_activeted") {
                            setActivated(true);
                        } else if (resStatus === "need_activate") {
                            setActivationStatus("need_activate");
                            setOrderData(response.data.order);
                            setPageTheme(response.data.page_theme);
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
        if (token && order_id && hash && baseURL) {
            fetchTicket();
        }
    }, [token, order_id, hash, baseURL]);

    const handleActivate = () => {
        if (!activated) {
            setIsActivating(true);
            axios({
                method: "POST",
                url: `${baseURL}/order/activate`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    hash: hash,
                    order_id: order_id,
                },
            })
                .then(function (response) {
                    if (response.status === 200) {
                        // После успешной активации переключаем состояние activated
                        setActivated(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    setIsActivating(false);
                });
        } else {
            // Если уже активированы, повторное нажатие запускает анимацию
            setShowAnimation(true);
            setTimeout(() => setShowAnimation(false), 2000); // длительность анимации, при необходимости скорректируйте время
        }
    };

    return (
        <div className="modal">
            {activated ? (
                <div className="order-mode activate">
                    <div className="activate_container">
                        <div className="activate_wrapper">
                            <div className="activate_animate">
                                <div className="activate_animate_file">
                                    <Lottie
                                        animationData={ticketJump}
                                        loop={false}
                                    />
                                </div>
                            </div>
                            <div className="activate_instruction">
                                <span className="activate_instruction_title">
                                    {Localized.ActivateEverythingOk}
                                </span>
                            </div>
                            <div className="activate_btns">
                                <div
                                    className="activate_activate-btn"
                                    onClick={
                                        !isActivating
                                            ? handleActivate
                                            : undefined
                                    }>
                                    {!isActivating ? (
                                        <span className="activate_activate-btn_text">
                                            {Localized.ActivateGoodbye}
                                        </span>
                                    ) : (
                                        <div className="loader"></div>
                                    )}
                                </div>
                                <div
                                    className="activate_trouble-btn_link"
                                    onClick={handleTrouble}>
                                    <span className="activate_trouble-btn_link_text">
                                        {Localized.ActivateHaveaProblem}
                                    </span>
                                </div>
                            </div>
                            {showAnimation && (
                                <Lottie
                                    animationData={groovyWalkAnimation}
                                    loop={false}
                                    className="anim"
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : activationStatus === "need_activate" ? (
                <div className="order-mode activate">
                    <div className="activate_container">
                        <div className="activate_wrapper">
                            <div className="activate_instruction">
                                <span className="activate_instruction_title">
                                    {Localized.ActivateTitle}
                                </span>
                                <span className="activate_instruction_item">
                                    {Localized.ActivateStep1}
                                </span>
                                <span className="activate_instruction_item">
                                    {Localized.ActivateStep2}
                                </span>
                                <span className="activate_instruction_item">
                                    {Localized.ActivateStep3}
                                </span>
                            </div>
                            <div className="activate_order">
                                {orderData.map((item, index) => {
                                    return (
                                        <div
                                            className="activate_order-item"
                                            style={{
                                                backgroundColor:
                                                    pageTheme?.second_color,
                                            }}
                                            key={index}>
                                            <div className="activate_order-item_category">
                                                <span className="activate_order-item_category_text">
                                                    {Localized.ActivateTicket}
                                                </span>
                                                <span className="activate_order-item_category_value">
                                                    {item.category_title}
                                                </span>
                                            </div>
                                            <div className="activate_order-item_barcode">
                                                <span className="activate_order-item_barcode_text">
                                                    {Localized.ActivateCode}
                                                </span>
                                                <span className="activate_order-item_barcode_value">
                                                    {item.barcode}
                                                </span>
                                            </div>
                                            <div className="activate_order-item_serial">
                                                <span className="activate_order-item_serial_text">
                                                    {Localized.ActivateSerial}
                                                </span>
                                                <span className="activate_order-item_serial_value">
                                                    {item.serial_number}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="activate_btns">
                                <div
                                    className="activate_activate-btn"
                                    onClick={
                                        !isActivating
                                            ? handleActivate
                                            : undefined
                                    }>
                                    {!isActivating ? (
                                        <span className="activate_activate-btn_text">
                                            {Localized.ActivateActivate}
                                        </span>
                                    ) : (
                                        <div className="loader"></div>
                                    )}
                                </div>
                                <div
                                    className="activate_trouble-btn"
                                    onClick={handleTrouble}>
                                    <span className="activate_trouble-btn_text">
                                        {Localized.ActivateHaveaProblem2}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="modal">Загрузка...</div>
            )}
        </div>
    );
};

export default ActivateStep;
