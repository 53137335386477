import Lottie from "lottie-react";
import groovyWalkAnimation from "./anim.json";
import './style.scss';
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

const Loader = () => {

    const { loaderWindow } = useAppSelector((state: RootState) => state.windowSlice);

    // if(loaderWindow) {
        return (
            <div className="anim">
                <div className="anim_container">
                    <div className="anim_wrapper">
                        <div className="anim_loader">
                            <Lottie animationData={groovyWalkAnimation} loop={true} />
                        </div>
                    </div>
                </div>
            </div> 
        );
    // }

    return null;
    
};

export default Loader;
