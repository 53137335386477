import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Localized from "../../../Localized";
import {
    popRoute,
    setAnimationDirection,
    setStep,
} from "../../../redux/slices/windowSlice";
import { useLanguage } from "../../../contexts/LanguageContext";
import {
    selectDeliveryMethod,
    selectPaymentMethod,
} from "../../../redux/slices/orderSlice";
import "./style.scss";
import iconButton from "../../../img/header/arrow-left.svg";
import langIcon from "../../../img/header/lang.svg";
import langArrowIcon from "../../../img/header/lang-arrow.svg";
import Timer from "./timer";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { stepCurrent, isTestMode, headerCutsom, routeStack, step } =
        useAppSelector((state: RootState) => state.windowSlice);
    const { selectedPaymentMethod, selectedDeliveryMethod } = useAppSelector(
        (state: RootState) => state.orderSlice
    );
    const { language, switchLanguage } = useLanguage();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { token } = useAppSelector((state: RootState) => state.userSlice);

    const handleBack = () => {
        if (routeStack.length > 0) {
            dispatch(popRoute());
            dispatch(selectPaymentMethod(null));
        }
    };

    useEffect(() => {
        if (selectedPaymentMethod != null && ["checkout"].includes(step)) {
            dispatch(selectPaymentMethod(null));
        }

        if (selectedDeliveryMethod != null && ["checkout"].includes(step)) {
            dispatch(selectDeliveryMethod(null));
        }
    }, [step]);

    useEffect(() => {
        if (routeStack.length > 0) {
            const previousRoute = routeStack[routeStack.length - 1];
            if (previousRoute) {
                dispatch(setStep(previousRoute.step));
                dispatch(setAnimationDirection(previousRoute.swipe));
                navigate(`${previousRoute.path}`, {
                    replace: true,
                });
            }
        } else {
            if (token) {
                dispatch(setStep("widget"));
                dispatch(setAnimationDirection("down"));
                navigate("/widget", {
                    replace: false,
                });
            }
        }
    }, [routeStack, dispatch, navigate]);

    const getBack = () => {
        switch (step) {
            case "checkout":
            case "payment_methods":
            case "delivery_methods":
                return (
                    <IconButton
                        className="header_back"
                        size="small"
                        onClick={() => handleBack()}>
                        <img src={iconButton} alt="back" />
                    </IconButton>
                );
            default:
                return null;
        }
    };

    const getTitle = () => {
        if (headerCutsom) {
            return headerCutsom;
        }
        switch (step) {
            case "loading":
                return "Пожалуйста, подождите...";
            case "widget":
                return Localized.HeaderSelectTicket;
            case "checkout":
                return Localized.HeaderYourOrder;
            case "payment_methods":
                return Localized.HeaderPaymentMethods;
            case "delivery_methods":
                return Localized.HeaderDeliveryMethods;
            case "delivery_methods_address":
                return Localized.HeaderDeliveryMethodsAddress;
            case "payment_step":
                return Localized.HeaderPaymentStep;
            case "final":
                return Localized.HeaderFinal;
            case "end":
                return Localized.HeaderEnd;
            case "activate":
                return Localized.HeaderActivate;
            default:
                return "Ошибка";
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectLanguage = (lang: string) => {
        switchLanguage(lang);
        setIsDropdownOpen(false);
    };

    return (
        <header className="header">
            <div className="header_container">
                <div className="header_wrapper">
                    {getBack()}
                    <div className="header_center">
                        <h1 className="header_title">{getTitle()}</h1>
                        <Timer />
                    </div>
                    {["widget", "activate"].includes(step) && (
                        <div className="header_lang" onClick={toggleDropdown}>
                            <img
                                src={langIcon}
                                alt=""
                                className="header_lang_icon"
                            />
                            <span className="header_lang_text">
                                {language.toUpperCase()}
                            </span>
                            {isDropdownOpen && (
                                <div className="header_lang_dropdown">
                                    <div
                                        className="header_lang_option"
                                        onClick={() => selectLanguage("BE")}>
                                        BE
                                    </div>
                                    <div
                                        className="header_lang_option"
                                        onClick={() => selectLanguage("UK")}>
                                        UK
                                    </div>
                                    <div
                                        className="header_lang_option"
                                        onClick={() => selectLanguage("EN")}>
                                        EN
                                    </div>
                                    <div
                                        className="header_lang_option"
                                        onClick={() => selectLanguage("PL")}>
                                        PL
                                    </div>
                                    <div
                                        className="header_lang_option"
                                        onClick={() => selectLanguage("RU")}>
                                        RU
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {isTestMode ? (
                <div className="header_testmode">
                    <span className="header_testmode_text">
                        {Localized.HeaderTestMode}
                    </span>
                </div>
            ) : null}
        </header>
    );
};

export default Header;
