import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import "./style.scss";
import { selectDeliveryAddress } from "../../../redux/slices/orderSlice";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ru from "react-phone-input-2/lang/ru.json";
import Localized from "../../../Localized";

const DeliveryMethodsAddress = () => {
    const dispatch = useAppDispatch();
    const { deliveryMethods, deliveryAddress } = useAppSelector(
        (state: RootState) => state.orderSlice
    );

    const [lastName, setLastName] = useState(deliveryAddress.last_name);
    const [firstName, setFirstName] = useState(deliveryAddress.first_name);
    const [patronymic, setPatronymic] = useState(deliveryAddress.patronymic);
    const [phone, setPhone] = useState(deliveryAddress.phone);

    const [addressInput, setAddressInput] = useState("");
    const [addressOptions, setAddressOptions] = useState<any[]>([]);
    const [showAddressLine, setShowAddressLine] = useState(true);
    const [postalCode, setPostalCode] = useState(deliveryAddress.postcode);
    const [city, setCity] = useState(deliveryAddress.city);
    const [cityType, setCityType] = useState(deliveryAddress.city_type);
    const [region, setRegion] = useState(deliveryAddress.region);
    const [district, setDistrict] = useState(deliveryAddress.district);
    const [streetType, setStreetType] = useState(deliveryAddress.street_type);
    const [street, setStreet] = useState(deliveryAddress.street);
    const [building, setBuilding] = useState(deliveryAddress.building);
    const [apartment, setApartment] = useState(deliveryAddress.apartment);

    useEffect(() => {
        const fetchAddresses = async () => {
            if (addressInput.length < 3) {
                setAddressOptions([]);
                return;
            }
            try {
                const response = await fetch(
                    `https://api.belpost.by/api/v1/postcodes/autocomplete?search=${addressInput}`
                );
                const data = await response.json();
                setAddressOptions(data);
            } catch (error) {
                console.error("Ошибка при получении адресов:", error);
            }
        };

        const delayDebounceFn = setTimeout(() => {
            fetchAddresses();
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [addressInput]);

    useEffect(() => {
        const deliveryAddressPayload = {
            last_name: lastName,
            first_name: firstName,
            patronymic: patronymic,
            postcode: postalCode,
            region: region,
            district: district,
            city: city,
            city_type: cityType,
            street: street,
            street_type: streetType,
            building: building,
            apartment: apartment,
            phone: phone,
        };
        dispatch(selectDeliveryAddress(deliveryAddressPayload));
    }, [
        lastName,
        firstName,
        patronymic,
        postalCode,
        city,
        street,
        building,
        apartment,
        phone,
        dispatch,
    ]);

    if (!deliveryMethods) return null;

    return (
        <div className="delivery-methods-address">
            <div className="delivery-methods-address_container">
                <div className="delivery-methods-address_wrapper">
                    <div className="delivery-methods-address_form-group">
                        <span className="delivery-methods-address_form-group_label">
                            {Localized.DeliveryAddressRecipient}
                        </span>
                        <div className="delivery-methods-address_form-group_inputs">
                            <div className="delivery-methods-address_form-group_row">
                                <TextField
                                    label={Localized.DeliveryAddressLastname}
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    autoComplete="off"
                                    inputMode="none"
                                />
                                <TextField
                                    label={Localized.DeliveryAddressFirstname}
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    autoComplete="off"
                                    inputMode="none"
                                />
                                <TextField
                                    label={Localized.DeliveryAddressPatronymic}
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    value={patronymic}
                                    onChange={(e) =>
                                        setPatronymic(e.target.value)
                                    }
                                    autoComplete="off"
                                    inputMode="none"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="delivery-methods-address_form-group">
                        <span className="delivery-methods-address_form-group_label">
                            {Localized.DeliveryAddressPhoneNumber}
                        </span>
                        <div className="delivery-methods-address_form-group_inputs">
                            <ReactPhoneInput
                                country="by"
                                onlyCountries={["by"]}
                                localization={ru}
                                specialLabel=""
                                placeholder="+375 (29) 123-45-67"
                                inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFormat: true,
                                }}
                                value={phone}
                                containerStyle={{ width: "100%" }}
                                inputStyle={{
                                    width: "100%",
                                    height: "40px",
                                    paddingLeft: "12px",
                                }}
                                buttonStyle={{ display: "none" }}
                                onChange={(phone) => setPhone(phone)}
                            />
                        </div>
                    </div>
                    {showAddressLine ? (
                        <div className="delivery-methods-address_form-group">
                            <span className="delivery-methods-address_form-group_label">
                                {Localized.DeliveryAddressTitle}
                            </span>
                            <div className="delivery-methods-address_form-group_inputs">
                                <Autocomplete
                                    freeSolo
                                    openOnFocus
                                    inputValue={addressInput}
                                    options={addressOptions}
                                    filterOptions={(options) => options}
                                    fullWidth
                                    
                                    autoComplete={true}
                                    getOptionLabel={(option) =>
                                        option.autocomplete_address
                                    }
                                    onInputChange={(event, newInputValue) => {
                                        setAddressInput(newInputValue);
                                    }}
                                    onChange={(event, selectedOption: any) => {
                                        if (selectedOption) {
                                            setPostalCode(
                                                selectedOption.postcode || ""
                                            );
                                            setCity(selectedOption.city || "");
                                            setStreet(
                                                selectedOption.street || ""
                                            );
                                            setCityType(
                                                selectedOption.city_type || ""
                                            );
                                            setStreetType(
                                                selectedOption.street_type || ""
                                            );
                                            setDistrict(
                                                selectedOption.district || ""
                                            );
                                            setRegion(
                                                selectedOption.region || ""
                                            );
                                            setShowAddressLine(false);
                                        }
                                    }}
                                    inputMode="none"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                Localized.DeliveryAddressMainInput
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            autoComplete="off"
                                            name="no-autocomplete-address"
                                        />
                                    )}
                                    
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="delivery-methods-address_form-group">
                            <span className="delivery-methods-address_form-group_label">
                                {Localized.DeliveryAddressTitle}
                            </span>
                            <div className="delivery-methods-address_form-group_inputs">
                                <div className="delivery-methods-address_form-group_row">
                                    <TextField
                                        label={Localized.DeliveryAddressCountry}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        defaultValue="Беларусь"
                                        disabled
                                    />
                                    <TextField
                                        label={
                                            Localized.DeliveryAddressPostcode
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={postalCode}
                                        onChange={(e) =>
                                            setPostalCode(e.target.value)
                                        }
                                    />
                                    <TextField
                                        label={Localized.DeliveryAddressCity}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={city}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="delivery-methods-address_form-group_row">
                                    <TextField
                                        label={Localized.DeliveryAddressStreet}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={street}
                                        onChange={(e) =>
                                            setStreet(e.target.value)
                                        }
                                        sx={{ flex: 2 }}
                                    />
                                    <TextField
                                        label={Localized.DeliveryAddressHouse}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={building}
                                        onChange={(e) =>
                                            setBuilding(e.target.value)
                                        }
                                        sx={{ flex: 1, maxWidth: 100 }}
                                    />
                                    <TextField
                                        label={
                                            Localized.DeliveryAddressApartment
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        value={apartment}
                                        onChange={(e) =>
                                            setApartment(e.target.value)
                                        }
                                        sx={{ flex: 1, maxWidth: 100 }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeliveryMethodsAddress;
