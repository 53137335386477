import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { sha256 } from "js-sha256";
import "./style.scss";
import {
    useCheckTokenMutation,
    useGetMaxCountProductsMutation,
    useGetProductsMutation,
    useGetTokenMutation,
} from "../../redux/api";
import WaitingStep from "../Steps/WaitingStep";
import Loader from "../Loader";
import { setEventId } from "../../redux/slices/orderSlice";
import { setBaseUrl } from "../../redux/slices/userSlice";
import { setGlobalMsg } from "../../redux/slices/windowSlice";
import Localized from "../../Localized";

const Initializer = () => {
    const dispatch = useAppDispatch();
    const { token, eventId, step } =
        useAppSelector((state: RootState) => ({
            ...state.windowSlice,
            ...state.userSlice,
            ...state.orderSlice,
        }));

    const [searchParams] = useSearchParams();
    const event_id = searchParams.get("event_id");
    const ticket_id = searchParams.get("ticket_id");
    const mode = searchParams.get("mode");
    const [timestamp, setTimestamp] = useState(Date.now());
    const [checkToken] = useCheckTokenMutation();
    const [
        getToken,
        {
            data: tokenLocal,
            isSuccess: isTokenSuccess,
            isError: isTokenError,
            isLoading: isTokenLoading,
        },
    ] = useGetTokenMutation();

    const [
        getProducts,
        {
            data: productsData,
            isSuccess: isProductsSuccess,
            isLoading: isProductsLoading,
            isError: isProductsError,
        },
    ] = useGetProductsMutation();

    const [
        getMaxCountProducts,
        {
            data: countProductsData,
            isSuccess: isCountProductsSuccess,
            isLoading: isCountProductsLoading,
            isError: isCountProductsError,
        },
    ] = useGetMaxCountProductsMutation();

    useEffect(() => {
        if (!eventId) {
            dispatch(setEventId(event_id));
        }

        switch (mode) {
            case "prod":
                dispatch(setBaseUrl("prod"));
                break;
            case "dev":
                dispatch(setBaseUrl("dev"));
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (!token && !tokenLocal) {
            const fingerprint = sha256(
                [
                    navigator.language,
                    JSON.stringify(navigator.languages),
                    window.screen.width,
                    window.screen.height,
                    navigator.userAgent,
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                    navigator.hardwareConcurrency || "unknown",
                    navigator.maxTouchPoints || "unknown",
                    window.screen.colorDepth,
                    window.screen.pixelDepth,
                ].join("-")
            );
            if (event_id) {
                getToken({
                    event_id: event_id,
                    fingerprint,
                })
                    .unwrap()
                    .then(() => {
                        // Handle success
                    })
                    .catch((action) => {
                        if (action.payload) {
                            let code =
                                typeof action.payload.status === "number"
                                    ? action.payload.status
                                    : action.payload["originalStatus"];
                            switch (code) {
                                case 503:
                                    dispatch(
                                        setGlobalMsg(
                                            Localized.SomeTechnicalProblems
                                        )
                                    );
                                    break;
                                case 429:
                                    // Handle 429 error
                                    break;
                                case 404:
                                    // Handle 404 error
                                    break;
                                default:
                                    // Handle other errors
                                    break;
                            }
                        }
                    });
            }
        }
    }, []);

    useEffect(() => {
        if (isTokenSuccess) {
            // setShouldFetchProducts(true);
            getProducts({
                product_id: ticket_id ? ticket_id : 0,
                event_id: event_id,
            });
        }
    }, [isTokenSuccess]);

    useEffect(() => {
        if (isTokenLoading || isProductsLoading) {
            console.log("Loading...", isTokenLoading, isProductsLoading);
        }
    }, [isTokenLoading, isProductsLoading]);

    useEffect(() => {
        let tokenIntervalId;
        const checkInterval = 30000;

        if (
            token &&
            [
                "widget",
                "checkout",
                "payment_methods",
                "delivery_methods",
                "delivery_methods_address",
            ].includes(step)
        ) {
            tokenIntervalId = setInterval(() => {
                checkToken();
                getMaxCountProducts({
                    event_id: eventId,
                    _timestamp: timestamp,
                });
            }, checkInterval);
        }

        return () => clearInterval(tokenIntervalId);
    }, [token, checkToken, step]);

    if (isTokenLoading || isProductsLoading) {
        return <Loader />;
    }

    if (isTokenError || isProductsError) {
        return <WaitingStep />;
    }

    return <Outlet />;
};

export default Initializer;
