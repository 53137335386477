import React, { useState, useEffect } from "react";
import "./style.scss";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
    closeInputEmail,
    navigateToPage,
} from "../../../redux/slices/windowSlice";
import {
    handlePaymentMethodsFlow,
    setEmailIncorrect,
} from "../../../redux/slices/orderSlice";
import { useSetEmailMutation } from "../../../redux/api";
import Localized from "../../../Localized";
import { useLanguage } from "../../../contexts/LanguageContext";
import Checkbox from "@mui/material/Checkbox";

const EmailModal = () => {
    const { language } = useLanguage();
    const dispatch = useAppDispatch();
    const { isEmailStep } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const { isEmailIncorrect, selectedPaymentMethod, selectedDeliveryMethod } =
        useAppSelector((state: RootState) => state.orderSlice);
    const [setEmail] = useSetEmailMutation();
    const [emailValue, setEmailValue] = useState("");
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [emailLetter, setEmailLetter] = useState([]);
    const [showLetters, setShowLetters] = useState(false);
    const [pulseAnimation, setPulseAnimation] = useState(false);
    const [shakeAnimation, setShakeAnimation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    // Добавляем новое состояние для принятия соглашения
    const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);

    useEffect(() => {
        if (isEmailIncorrect) {
            setShakeAnimation(true);
            setTimeout(() => {
                setShakeAnimation(false);
                setIsButtonDisabled(true);
            }, 500);
        }
    }, [isEmailIncorrect]);

    if (!selectedPaymentMethod) {
        return null;
    }

    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleBackStep = () => {
        if (!showLetters) {
            dispatch(closeInputEmail(false));
            dispatch(setEmailIncorrect(false));
            setEmailValue("");
            setIsEmailEmpty(false);
            // Сброс флага соглашения
            setIsAgreementAccepted(false);
        } else {
            setShowLetters(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setEmailIncorrect(false));
        const newEmailValue = event.target.value;
        setEmailValue(newEmailValue);
        setIsEmailEmpty(!isValidEmail(newEmailValue));
        setEmailLetter(newEmailValue.split(""));
        setIsButtonDisabled(false);
    };

    const handleSendEmail = async () => {
        // Если требуется соглашение, проверяем, отмечен ли чекбокс
        if (selectedDeliveryMethod.address_required && !isAgreementAccepted) {
            return;
        }

        if (!isEmailEmpty && isValidEmail(emailValue)) {
            const emailData = {
                email: emailValue,
            };

            try {
                if (showLetters) {
                    setIsLoading(true);
                    await setEmail(emailData)
                        .unwrap()
                        .then(() => {
                            dispatch(handlePaymentMethodsFlow());
                        });
                    setIsLoading(false);

                    if (isEmailIncorrect) {
                        setIsButtonDisabled(true);
                        setShakeAnimation(true);
                        setTimeout(() => {
                            setShakeAnimation(false);
                        }, 500);
                    }
                } else {
                    setPulseAnimation(true);
                    setShowLetters(true);
                }
            } catch (error) {
                console.error("Ошибка при выполнении запроса:", error);
                setIsLoading(false);
            }
        } else {
            setIsEmailEmpty(true);
        }
    };

    const colorBtn = selectedPaymentMethod.icon_color;
    // Определяем флаг доступности кнопки с учётом состояния соглашения
    const isSubmitDisabled =
        isEmailIncorrect ||
        isButtonDisabled ||
        isLoading ||
        (selectedDeliveryMethod.address_required && !isAgreementAccepted);

    return isEmailStep ? (
        <div className="email-modal">
            <div
                className={`email-modal_wrapper ${pulseAnimation ? "" : ""}`}
                onAnimationEnd={() => setPulseAnimation(false)}>
                {!showLetters ? (
                    <span className="email-modal_text">
                        {selectedDeliveryMethod.address_required
                            ? Localized.EmailModalDeliveryText
                            : Localized.EmailModalText}
                    </span>
                ) : null}

                <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    className={`email-modal_input ${
                        showLetters ? "hidden" : ""
                    }`}
                    error={isEmailIncorrect || isEmailEmpty}
                    value={emailValue}
                    onChange={handleInputChange}
                    type="email"
                    helperText={
                        (isEmailIncorrect || isEmailEmpty) &&
                        Localized.EmailModalPleactCorrect
                    }
                />

                <div
                    className={`email-modal_letters ${
                        showLetters
                            ? `visible pulse ${shakeAnimation ? "shake" : ""}`
                            : ""
                    }`}>
                    {emailLetter.map((item, index) => (
                        <span key={index} className="email-modal_letters_item">
                            {item}
                        </span>
                    ))}
                </div>

                {showLetters ? (
                    <span
                        className="email-modal_text"
                        dangerouslySetInnerHTML={{
                            __html: Localized.EmailImportant,
                        }}></span>
                ) : null}

                {/* Если для выбранного способа доставки требуется соглашение, показываем чекбокс */}
                {selectedDeliveryMethod.address_required && (
                    <label className="ticket_promo">
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={isAgreementAccepted}
                            onChange={(e) =>
                                setIsAgreementAccepted(e.target.checked)
                            }
                        />
                        <p className="ticket_promo_text">
                            {Localized.Agreement}
                        </p>
                    </label>
                )}

                <div className="email-modal_btns">
                    <div
                        className="email-modal_cancel"
                        onClick={handleBackStep}>
                        {showLetters
                            ? Localized.EmailChange
                            : Localized.EmailBack}
                    </div>
                    <div
                        className="email-modal_submit"
                        style={{
                            background: isSubmitDisabled ? "#ccc" : colorBtn,
                        }}
                        onClick={
                            !isSubmitDisabled ? handleSendEmail : undefined
                        }>
                        {isLoading ? (
                            <div className="loader"></div>
                        ) : showLetters && !isEmailIncorrect ? (
                            Localized.EmailCorrect
                        ) : (
                            Localized.EmailContinue
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default EmailModal;