import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import "./style.scss";
import { setDeliveryAddressDoubleCheck } from "../../../redux/slices/orderSlice";
import { popRoute } from "../../../redux/slices/windowSlice";
import Localized from "../../../Localized";

const DeliveryMethodsAddressConfirm = () => {
    const dispatch = useAppDispatch();
    const { deliveryAddress, isDeliveryAddressDoubleCheck } = useAppSelector(
        (state: RootState) => state.orderSlice
    );

    if (!isDeliveryAddressDoubleCheck) {
        return null;
    }

    const handleCancel = () => {
        dispatch(setDeliveryAddressDoubleCheck(false));
    };

    const handleSubmit = () => {
        dispatch(setDeliveryAddressDoubleCheck(false));
        dispatch(popRoute());
    };

    return (
        <div className="delivery-methods-address-confirm">
            <div className="delivery-methods-address-confirm_wrapper">
                <span className="delivery-methods-address-confirm_text">
                    {Localized.DeliveryNotfound}
                </span>
                <div className="delivery-methods-address-confirm_address">
                    {deliveryAddress.postcode && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {Localized.DeliveryNotfoundPostcode}:{" "}
                            {deliveryAddress.postcode}
                        </span>
                    )}
                    {deliveryAddress.region && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {Localized.DeliveryNotfoundRegion}:{" "}
                            {deliveryAddress.region}
                        </span>
                    )}
                    {deliveryAddress.district && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {Localized.DeliveryNotfoundRegion}:{" "}
                            {deliveryAddress.district}
                        </span>
                    )}
                    {(deliveryAddress.city_type || deliveryAddress.city) && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {deliveryAddress?.city_type} {deliveryAddress?.city}
                        </span>
                    )}
                    {(deliveryAddress.street_type ||
                        deliveryAddress.street) && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {deliveryAddress?.street_type}{" "}
                            {deliveryAddress?.street}
                        </span>
                    )}
                    {deliveryAddress.building && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {Localized.DeliveryNotfoundHouse}:{" "}
                            {deliveryAddress.building}
                        </span>
                    )}
                    {deliveryAddress.apartment && (
                        <span className="delivery-methods-address-confirm_address_text">
                            {Localized.DeliveryNotfoundApartment}:{" "}
                            {deliveryAddress.apartment}
                        </span>
                    )}
                </div>
                <div className="delivery-methods-address-confirm_btns">
                    <button
                        className="delivery-methods-address-confirm_cancel"
                        onClick={handleCancel}>
                        {Localized.DeliveryNotfoundChangeBtn}
                    </button>
                    <button
                        className="delivery-methods-address-confirm_submit"
                        onClick={handleSubmit}>
                        {Localized.DeliveryNotfoundSubmitBtn}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeliveryMethodsAddressConfirm;
