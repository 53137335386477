import React from "react";
import checkpoint from '../../../../img/paymentStep/checkmark.svg';

const PaymentMethodItem = ({cardIcon, title, description, color, isSelected, onClick}) => {
    return (
        <div
            className="payment-methods_item"
            style={{ borderColor: isSelected ?  color : "#ddd" }} 
            onClick={onClick}
            >
            <div className="payment-methods_item_wrapper">
                <img
                    src={cardIcon}
                    alt="Card"
                    className="payment-methods_item_icon"
                />
                <div className="payment-methods_item_info">
                    <span className="payment-methods_item_title">
                        {title}
                    </span>
                    {description && (
                        <span className="payment-methods_item_description">
                            {description}
                        </span>
                    )}
                </div>
            </div>
            {isSelected && (
                <div
                    className="payment-methods_item_checkmark"
                    style={{ background:  color }}>
                    <img src={checkpoint} alt="checkmark" />
                </div>
            )}
        </div>
    );
};

export default PaymentMethodItem;
