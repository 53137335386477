import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import axios from "axios";
import Header from "../../Window/Header";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./anim.json";
import ticketJump from "./ok.json";
import ticketBtn from "./anim-btn.json";
import Localized from "../../../Localized";
import TagManager from "react-gtm-module";
import { saveAs } from "file-saver";
import "./style.scss";
import { navigateToPage, setStep } from "../../../redux/slices/windowSlice";
import { useLanguage } from "../../../contexts/LanguageContext";

const FinalStep = () => {
    const dispatch = useAppDispatch();
    const [url, setUrl] = useState<Blob | null>(null);
    const [baseURL, setBaseURL] = useState(process.env.REACT_APP_API_URL);
    const [attemptCount, setAttemptCount] = useState(0);
    const { language, switchLanguage } = useLanguage();

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    let emailUrl = searchParams.get("email");
    let token = searchParams.get("token");
    let mode = searchParams.get("mode");
    let lang = searchParams.get("lang");
    let deliveryType = searchParams.get("deliveryType") || "online";

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: "finalStep",
            },
        });
        if (mode === "dev") {
            setBaseURL(process.env.REACT_APP_QA__URL);
        }

        dispatch(setStep("final"));

        console.log(deliveryType);
    }, []);

    useEffect(() => {
        if (lang) {
            console.log("Switching language to: ", lang.toLowerCase());
            // Вызывайте обновление через контекст, которое изменяет состояние.
            switchLanguage(lang);
            // Если Localized.setLanguage меняет глобальное состояние,
            // то можно делать так, но важно, чтобы компонент реагировал на это изменение.
            Localized.setLanguage(lang);
        }
    }, [lang]);
    
    useEffect(() => {
        const fetchTicket = () => {
            axios({
                method: "GET",
                url: `${baseURL}/tickets?token=${token}`,
                responseType: "arraybuffer",
            })
                .then(function (response) {
                    if (response.status === 200) {
                        setUrl(
                            new Blob([response.data], {
                                type: "application/pdf",
                            })
                        );
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            if (attemptCount < 5) {
                const timerId = setTimeout(() => {
                    setAttemptCount(attemptCount + 1);
                }, 5000);

                return () => clearTimeout(timerId);
            }
        };

        fetchTicket();
    }, [token, attemptCount]);

    const handleDownload = () => {
        if (!url) return;
        saveAs(url, "ticket.pdf");
    };

    const getTicketBtn = () => {
        if (attemptCount < 5) {
            return (
                <div className="final_btns">
                    <div className="final_btns_ticket">
                        <div
                            className="loader"
                            style={{ display: "block" }}></div>
                    </div>
                </div>
            );
        } else if (attemptCount == 5) {
            return (
                <h2 className="final_text">{Localized.Finalneedmoretime}</h2>
            );
        }
    };

    return (
        <div className="modal">
            <div className="order-mode final">
                <div className="final_container">
                    <div className="final_wrapper">
                        <div className="final_animate">
                            <div className="final_animate_file">
                                <Lottie
                                    animationData={ticketJump}
                                    loop={false}
                                />
                            </div>
                        </div>
                        <h1 className="final_title">
                            {Localized.FinalThankYou}
                        </h1>
                        {deliveryType === "belpost" ? (
                            <h2 className="final_text">
                                {Localized.formatString(
                                    Localized.FinalTextBelpost,
                                    {
                                        email: <b>{emailUrl}</b>,
                                    }
                                )}
                            </h2>
                        ) : (
                            <h2 className="final_text">
                                {Localized.formatString(Localized.FinalText, {
                                    email: <b>{emailUrl}</b>,
                                })}
                            </h2>
                        )}
                        {deliveryType === "belpost" ? (
                            // Для belpost показываем кнопку скачивания только если url имеется,
                            // иначе ничего не рендерим
                            url && (
                                <>
                                    <div className="final_btns">
                                        <div
                                            className="final_btns_ticket"
                                            onClick={() => handleDownload()}>
                                            <span className="final_btns_ticket_text">
                                                {Localized.FinalDownloadTicket}
                                            </span>
                                        </div>
                                    </div>
                                    <Lottie
                                        animationData={ticketBtn}
                                        loop={false}
                                        className="final_btns-animate"
                                    />
                                </>
                            )
                        ) : // Если не belpost, то стандартное поведение с лоадером
                        url ? (
                            <>
                                <div className="final_btns">
                                    <div
                                        className="final_btns_ticket"
                                        onClick={() => handleDownload()}>
                                        <span className="final_btns_ticket_text">
                                            {Localized.FinalDownloadTicket}
                                        </span>
                                    </div>
                                </div>
                                <Lottie
                                    animationData={ticketBtn}
                                    loop={false}
                                    className="final_btns-animate"
                                />
                            </>
                        ) : (
                            getTicketBtn()
                        )}
                    </div>
                </div>
            </div>
            <div className="anim">
                <Lottie animationData={groovyWalkAnimation} loop={false} />
            </div>
        </div>
    );
};

export default FinalStep;
