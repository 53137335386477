import "./style.scss";
import Item from "./Item";
import Localized from "../../../Localized";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useRef } from "react";
import paymentOk from "../../../img/paymentStep/paymentOk.svg";
import { useLanguage } from "../../../contexts/LanguageContext";

const Main = () => {
    const { language } = useLanguage();

    const { goods, selected } = useAppSelector(
        (state: RootState) => state.orderSlice
    );
    const { stepCurrent } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    const { token } = useAppSelector(
        (state: RootState) => state.userSlice
    );

    const selectedItems = goods
        .filter((item) => selected.includes(item.categoryId))
        .sort(
            (a, b) =>
                selected.indexOf(a.categoryId) - selected.indexOf(b.categoryId)
        )
        .map((product) => {
            return (
                <Item
                    key={product.categoryId}
                    product={product}
                    isSelected={true}
                />
            );
        });

    const other = goods
        .filter((item) => !selected.includes(item.categoryId))
        .map((product) => {
            return (
                <Item
                    key={product.categoryId}
                    product={product}
                    isSelected={false}
                />
            );
        });

    if (goods.length === 0 && token) {
        return (
            <div className="close-step">
                <div className="close-step_container">
                    <div className="close-step_wrapper">
                        <div className="close-step_info">
                            <span className="close-step_info_amount">
                                <b>{Localized.Soldout}</b>
                            </span>
                            <img src={paymentOk} alt="Card" />
                            <span className="close-step_info_text">
                                {Localized.SoldoutText}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="main order-mode_selected">
            <div className="main_container">
                <div className="main_wrapper">
                    <div className="main_selected_items">
                        <h1
                            className="main_select"
                            style={
                                selected.length === 0 ? { opacity: "0" } : {}
                            }>
                            {Localized.SelectedText}
                        </h1>
                        {selectedItems}
                        <div className="separator"></div>
                        <h1
                            className="main_other"
                            style={
                                other.length === 0 || selected.length === 0
                                    ? { display: "none" }
                                    : {}
                            }>
                            {Localized.YouCanAddText}
                        </h1>
                        {other}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
