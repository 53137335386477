import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import Localized from "../../../../Localized";
import {
    useUpdateProductsMutation,
    useSetPaymentMethodMutation,
    useValidateDeliveryAddressMutation,
    useSetDeliveryOptionMutation,
} from "../../../../redux/api";
import {
    isButtonLoader,
    navigateToPage,
    openInputEmail,
    popRoute,
} from "../../../../redux/slices/windowSlice";
import {
    handleConfirmCartFlow,
    handleGetDeliveryMethodsFlow,
    handleGetPaymentMethodsFlow,
    setDeliveryAddressDoubleCheck,
} from "../../../../redux/slices/orderSlice";
//handleConfirmCartFlow
import "./style.scss";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

const ButtonFooter = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { stepCurrent, loaderButton, routeStack, step } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const {
        totalCount,
        selectedPaymentMethod,
        selectedDeliveryMethod,
        goods,
        selected,
        customerEmail,
        eventId,
        isDeliveryAddressComplete,
        deliveryAddress,
    } = useAppSelector((state: RootState) => state.orderSlice);
    const { mail, isMailValid } = useAppSelector(
        (state: RootState) => state.userSlice
    );
    const [sendCart] = useUpdateProductsMutation();
    const [validateDeliveryAddress] = useValidateDeliveryAddressMutation();
    const [setDeliveryOption] = useSetDeliveryOptionMutation();

    const handleChange = async () => {
        if (!loaderButton) {
            switch (step) {
                case "widget":
                    dispatch(isButtonLoader(true));

                    const cartData = {
                        cart: goods
                            .filter((item) => item.count > 0)
                            .map((item) => ({
                                id: item.categoryId,
                                count: item.count,
                            })),
                        event_id: eventId,
                    };

                    let amount = 0;
                    let service = 0;
                    let totalPrice = 0;

                    goods
                        .filter((item) => selected.includes(item.categoryId))
                        .sort(
                            (a, b) =>
                                selected.indexOf(a.categoryId) -
                                selected.indexOf(b.categoryId)
                        )
                        .map((item) => {
                            amount =
                                amount +
                                Number(item.prices.standart) * item.count;
                            service =
                                service +
                                Number(item.prices.service) * item.count;
                            totalPrice = amount + service;
                            return true;
                        });

                    TagManager.dataLayer({
                        dataLayer: {
                            event: "addToCart",
                            total: totalPrice,
                            currency: "BYN",
                            items: goods
                                .filter((item) => item.count > 0)
                                .map((item) => ({
                                    id: item.categoryId,
                                    name: item.title,
                                    price: item.prices.standart,
                                    count: item.count,
                                })),
                        },
                    });

                    sendCart(cartData)
                        .unwrap()
                        .then((res) => {
                            dispatch(isButtonLoader(false));
                            dispatch(navigateToPage("checkout"));
                        })
                        .catch((err) => {
                            dispatch(isButtonLoader(false));
                        });
                    break;
                case "checkout":
                    dispatch(isButtonLoader(true));
                    dispatch(handleConfirmCartFlow())
                        .unwrap()
                        .then((res) => {
                            dispatch(handleGetDeliveryMethodsFlow())
                                .unwrap()
                                .then((res) => {
                                    if (res.length > 1) {
                                        dispatch(isButtonLoader(false));
                                        dispatch(
                                            navigateToPage("delivery_methods")
                                        );
                                    } else {
                                        dispatch(handleGetPaymentMethodsFlow())
                                            .unwrap()
                                            .then((res) => {
                                                if (res.length > 1) {
                                                    dispatch(
                                                        isButtonLoader(false)
                                                    );
                                                    dispatch(
                                                        navigateToPage(
                                                            "payment_methods"
                                                        )
                                                    );
                                                } else {
                                                    dispatch(
                                                        openInputEmail(true)
                                                    );
                                                }
                                            })
                                            .catch((err) => {
                                                dispatch(isButtonLoader(false));
                                            });
                                    }
                                })
                                .catch((err) => {
                                    dispatch(isButtonLoader(false));
                                });
                        })
                        .catch((err) => {
                            dispatch(isButtonLoader(false));
                        });
                    break;
                case "payment_methods":
                    if (selectedPaymentMethod) {
                        dispatch(openInputEmail(true));
                    }
                    break;
                case "delivery_methods":
                    if (
                        selectedDeliveryMethod &&
                        (!selectedDeliveryMethod.address_required ||
                            isDeliveryAddressComplete)
                    ) {
                        setDeliveryOption({
                            option_id: selectedDeliveryMethod.id,
                            ...(selectedDeliveryMethod?.address_required && { address: deliveryAddress })
                        })
                            .unwrap()
                            .then((res) => {
                                dispatch(handleGetPaymentMethodsFlow())
                                    .unwrap()
                                    .then((res) => {
                                        if (res.length > 1) {
                                            dispatch(isButtonLoader(false));
                                            dispatch(
                                                navigateToPage(
                                                    "payment_methods"
                                                )
                                            );
                                        } else {
                                            dispatch(openInputEmail(true));
                                        }
                                    })
                                    .catch((err) => {
                                        dispatch(isButtonLoader(false));
                                    });
                            })
                            .catch((err) => {
                                dispatch(isButtonLoader(false));
                            });
                    }
                    break;
                case "delivery_methods_address":
                    if (
                        selectedDeliveryMethod &&
                        selectedDeliveryMethod.address_required &&
                        isDeliveryAddressComplete
                    ) {
                        validateDeliveryAddress({
                            event_id: eventId,
                            postcode: deliveryAddress.postcode,
                            region: deliveryAddress.region,
                            district: deliveryAddress.district,
                            city: deliveryAddress.city,
                            city_type: deliveryAddress.city_type,
                            street: deliveryAddress.street,
                            street_type: deliveryAddress.street_type,
                            building: deliveryAddress.building,
                        })
                            .unwrap()
                            .then((res) => {
                                // dispatch(openInputEmail(true));
                                dispatch(setDeliveryAddressDoubleCheck(false));
                                dispatch(popRoute());
                            })
                            .catch((err) => {
                                dispatch(isButtonLoader(false));
                            });
                    }
                    break;
            }
        }
    };

    const handleBack = () => {
        if (routeStack.length > 0) {
            dispatch(popRoute());
        }
    };

    const getBtnText = () => {
        if (totalCount === 0) {
            return Localized.BtnOrderEmpty;
        }
        switch (step) {
            case "widget":
                return Localized.BtnLookOrder;
            case "checkout":
            case "payment_methods":
            case "delivery_methods_address":
                return Localized.BtnPlaceOrder;
            case "delivery_methods":
                return Localized.BtnPayOrder;
        }
    };

    const getClassButton = () => {
        if (
            (routeStack.length && totalCount === 0) ||
            (!selectedPaymentMethod && step === "payment_methods") ||
            (!selectedDeliveryMethod && step === "delivery_methods") ||
            (selectedDeliveryMethod?.address_required &&
                !isDeliveryAddressComplete &&
                step === "delivery_methods_address") ||
            (selectedDeliveryMethod?.address_required &&
                !isDeliveryAddressComplete &&
                step === "delivery_methods")
        ) {
            return "button-footer button-footer_empty";
        } else {
            return "button-footer button-footer_simple";
        }
    };

    const colorBtn = () => {
        return !selectedPaymentMethod
            ? "#34C759"
            : selectedPaymentMethod.icon_color;
    };

    const lightenDarkenColor = (col, amt) => {
        let usePound = false;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        const num = parseInt(col, 16);
        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00ff) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000ff) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    };

    // Использование
    const baseColor = "#6699CC"; // Пример цвета в формате HEX

    // Установите переменную в корневом элементе (или любом другом подходящем элементе)
    useEffect(() => {
        document.documentElement.style.setProperty(
            "--button-background-color",
            colorBtn()
        );

        const baseColor = colorBtn(); // Функция, возвращающая основной цвет кнопки
        const lighterColor = lightenDarkenColor(baseColor, 20); // Чуть светлее
        const darkerColor = lightenDarkenColor(baseColor, -20); // Чуть темнее

        document.documentElement.style.setProperty(
            "--button-background-color",
            baseColor
        );
        document.documentElement.style.setProperty(
            "--button-hover-color",
            lighterColor
        );
        document.documentElement.style.setProperty(
            "--button-active-color",
            darkerColor
        );
    }, [selectedPaymentMethod]);

    const shadowClass = stepCurrent === 0 ? "button-footer_shadow" : "";

    const isShow = () => {
        switch (step) {
            case "widget":
                if (totalCount > 0) {
                    return true;
                } else {
                    return false;
                }
            case "checkout":
            case "payment_methods":
            case "delivery_methods":
            case "delivery_methods_address":
                return true;
            default:
                return false;
        }
    };

    const isShowBack = () => {
        switch (step) {
            case "delivery_methods_address":
                return true;
            default:
                return false;
        }
    };

    return (
        <div
            className={`${getClassButton()} ${shadowClass}`}
            style={isShow() ? { transform: "translateY(0)" } : {}}>
            <div
                className={`button-footer_container ${
                    isShowBack() ? "show-back" : ""
                }`}>
                <div
                    className="button-footer_wrapper-back"
                    onClick={() => {
                        handleBack();
                    }}>
                    <span
                        className="button-footer_text"
                        style={
                            !loaderButton
                                ? { display: "block" }
                                : { display: "none" }
                        }>
                        {Localized.Cancel}
                    </span>
                </div>
                <div
                    className="button-footer_wrapper"
                    onClick={() => {
                        handleChange();
                    }}
                    style={{ background: colorBtn() }}>
                    <div
                        className="loader"
                        style={
                            loaderButton
                                ? { display: "block" }
                                : { display: "none" }
                        }></div>
                    <span
                        className="button-footer_text"
                        style={
                            !loaderButton
                                ? { display: "block" }
                                : { display: "none" }
                        }>
                        {getBtnText()}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ButtonFooter;
