import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { selectDeliveryMethod, selectPaymentMethod } from "../../../redux/slices/orderSlice";
import DeliveryMethodItem from "./DeliveryMethodItem";
import "./style.scss";

const DeliveryMethods = () => {
    const dispatch = useAppDispatch();
    const { deliveryMethods, selectedDeliveryMethod } = useAppSelector(
        (state: RootState) => state.orderSlice
    );

    if (!deliveryMethods) return;

    return (
        <div className="delivery-methods">
            <div className="delivery-methods_container">
                <div className="delivery-methods_wrapper">
                    {deliveryMethods.map((item, index) => (
                        <DeliveryMethodItem
                            key={index}
                            cardIcon={item.icon_url}
                            title={item.title}
                            description={item.description}
                            color={item.color}
                            onClick={() => dispatch(selectDeliveryMethod(item))}
                            isSelected={
                                selectedDeliveryMethod &&
                                selectedDeliveryMethod.id === item.id
                            }
                            addressRequired={item.address_required}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DeliveryMethods;
