import Localized from "../../../../../Localized";
import "./style.scss";
import { useLanguage } from "../../../../../contexts/LanguageContext";
import { increase, decrease } from "../../../../../redux/slices/orderSlice";
import { useAppDispatch } from "../../../../../app/hooks";

function SimpleBtn(count: number) {
    if (count > 0) {
        return "button-cart button-cart_simple selected";
    }
    return "button-cart button-cart_simple";
}

const ButtonAdd = ({ id, count, maxCount, totalCount, maxTotal }) => {
    const dispatch = useAppDispatch();
    const { language } = useLanguage();
    return (
        <div className={SimpleBtn(count)}>
            <button
                className="button-cart_remove"
                onClick={() => {
                    dispatch(decrease(id));
                }}>
                <div className="button-cart_remove_icon"></div>
            </button>
            <span
                className="button-cart_count"
                style={
                    count % 2
                        ? { animationName: "badge-incr" }
                        : { animationName: "badge-incr2" }
                }>
                {count}
            </span>
            <button
                className="button-cart_plus"
                onClick={() => {
                    dispatch(increase(id));
                }}
                style={
                    totalCount >= maxTotal || count >= maxCount
                        ? { background: "#C4C4C4", cursor: "not-allowed" }
                        : {}
                }>
                <span className="button-cart_plus_text">
                    {Localized.BtnAddToCartBtn}
                </span>
                <div className="button-cart_plus_icon"></div>
            </button>
        </div>
    );
};

export default ButtonAdd;
