import "./style.scss";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Localized from "../../../Localized";
import { useLanguage } from "../../../contexts/LanguageContext";

const WaitingStep = () => {
    const { language } = useLanguage();
    const { globalMsg } = useAppSelector(
        (state: RootState) => state.windowSlice
    );

    if (globalMsg) {
        return (
            <>
                <div className="waiting-step">
                    <div className="waiting-step_container">
                        <div className="waiting-step_wrapper">
                            <div className="waiting-step_info">
                                {/* <span className="waiting-step_info_amount">
                                    <b>Приносим извинения!</b>
                                </span> */}
                                <span className="waiting-step_info_text">
                                    {globalMsg}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="waiting-step">
                <div className="waiting-step_container">
                    <div className="waiting-step_wrapper">
                        <div className="waiting-step_info">
                            <span className="waiting-step_info_text">
                                <b>{Localized.ToomanyrequestsTitle}</b>
                            </span>
                            <span className="waiting-step_info_text">
                                {Localized.ToomanyrequestsText1}
                            </span>
                            <span className="waiting-step_info_text">
                                {Localized.ToomanyrequestsText2}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WaitingStep;
