import React, { useRef, useEffect, useState } from "react";
import "./style.scss";
import simpleCard from "../../../img/paymentStep/simpleCard.svg";
import paymentOk from "../../../img/paymentStep/paymentOk.svg";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { wait } from "@testing-library/user-event/dist/utils";
import Localized from "../../../Localized";

const PaymentStep = () => {
    const dispatch = useAppDispatch();
    const { stepCurrent } = useAppSelector(
        (state: RootState) => state.windowSlice
    );
    const {
        paymentToken,
        paymentURL,
        amountTotal,
        customerEmail,
        selectedPaymentMethod,
        selectedDeliveryMethod
    } = useAppSelector((state: RootState) => state.orderSlice);

    const { token, basicLanguage } = useAppSelector((state: RootState) => state.userSlice);

    //token
    const [paymentStatus, setPaymentStatus] = useState("");

    useEffect(() => {
        // Проверяем, есть ли токен и текущий шаг равен ли 3
        if (!paymentToken) return;

        // Функция для отправки сообщения
        const sendPostMessage = () => {
            const payload = {
                token: paymentToken,
                link: paymentURL,
                email: customerEmail,
                session_key: token,
                delivery_type: selectedDeliveryMethod.address_required ? "belpost" : "pickup",
                lang: basicLanguage
            };

            window.parent.postMessage(
                { event: "bePaidPayment", ...payload },
                "*"
            );

            // const url = new URL(payload.link);
            // //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // //@ts-ignore
            // new window.BeGateway({
            //     token: payload.token,
            //     checkout_url: url.origin,
            //     fromWebview: true,
            //     closeWidget: function(status) {
            //         if(status === 'successful') {
            //             // setConstUrl('https://store.ticketok.by/final?email='+ payload.email+'&token=' + payload.session_key);
            //         }
            //     }
            // }).createWidget();
        };

        // Задержка перед отправкой сообщения
        const timerId = setTimeout(sendPostMessage, 3000);

        // Очистка таймера при размонтировании компонента
        return () => clearTimeout(timerId);
    }, [paymentToken, stepCurrent, paymentURL]); // Добавляем в зависимости paymentURL и stepCurrent

    // if (stepCurrent != 4) {
    //     return <></>;
    // }

    if (paymentStatus == "") {
        return (
            <>
                <div className="payment-step">
                    <div className="payment-step_container">
                        <div className="payment-step_wrapper">
                            <div
                                className="payment-step_info"
                                style={{
                                    borderColor:
                                        selectedPaymentMethod.icon_color,
                                }}>
                                <span className="payment-step_info_amount">
                                    {Localized.ForPay}{" "}
                                    <b>{Number(amountTotal).toFixed(2)} Br</b>
                                </span>
                                <img
                                    src={selectedPaymentMethod.icon_url}
                                    alt="Card"
                                />
                                <span className="payment-step_info_text">
                                    {Localized.WillOpenPaymentStep}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (paymentStatus == "success") {
        return (
            <>
                <div className="payment-step">
                    <div className="payment-step_container">
                        <div className="payment-step_wrapper">
                            <div className="payment-step_info">
                                <span className="payment-step_info_amount">
                                    <b>Спасибо!</b>
                                </span>
                                <img src={paymentOk} alt="Card" />
                                <span className="payment-step_info_text">
                                    Оплата была получена!
                                    <br />
                                    Пожалуйста, подождите...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default PaymentStep;
