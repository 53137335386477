import * as React from "react";
import ReactDOM from "react-dom/client";
import { CSSTransition, TransitionGroup, SwitchTransition } from "react-transition-group";
import { useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";

import App from "./components/App";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import FinalStep from "./components/Steps/FinalStep";
import CloseSession from "./components/Steps/CloseSession";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Initializer from "./components/Initializer";
import Header from "./components/Window/Header";
import Footer from "./components/Window/Footer";
import SessionTimeout from "./components/TimerCloseSession/SessionTimeout";
import EmailModal from "./components/Steps/EmailModal";
import Main from "./components/Steps/Main";
import Checkout from "./components/Steps/Checkout";
import PaymentMethods from "./components/Steps/PaymentMethods";
import PaymentStep from "./components/Steps/PaymentStep";

import "./css/normalize.css";
import "./css/sf.css";
import "./style.scss";
import DeliveryMethods from "./components/Steps/DeliveryMethods";
import DeliveryMethodsAddress from "./components/Steps/DeliveryMethodsAddress";
import DeliveryMethodsAddressConfirm from "./components/Steps/DeliveryMethodsAddressConfirm";
import { LanguageProvider } from "./contexts/LanguageContext";
import ActivateStep from "./components/Steps/ActivateStep";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const AnimatedRoutes = () => {
    const location = useLocation();
    const { routeStack } = useAppSelector((state: RootState) => state.windowSlice);
    const [prevStackLength, setPrevStackLength] = React.useState(routeStack.length);
    const [animationDirection, setAnimationDirection] = React.useState("up");

    React.useLayoutEffect(() => {
        if (routeStack.length > prevStackLength) {
            setAnimationDirection("up");
        } else if (routeStack.length < prevStackLength) {
            setAnimationDirection("down");
        }
        setPrevStackLength(routeStack.length);
    }, [routeStack.length]);

    return (
        <TransitionGroup
            // childFactory={(child) =>
            //     React.cloneElement(child, { classNames: `page-${animationDirection}` })
            // }
        >
            <CSSTransition key={location.key} timeout={600} classNames={`page-${animationDirection}`} unmountOnExit>
                <Routes location={location}>
                    <Route path="/" element={<Initializer />}>
                        <Route path="widget" element={<Main />} />
                        <Route path="checkout" element={<Checkout />} />
                        <Route path="payment_methods" element={<PaymentMethods />} />
                        <Route path="delivery_methods" element={<DeliveryMethods />} />
                        <Route path="delivery_methods_address" element={<DeliveryMethodsAddress />} />
                        <Route path="payment_step" element={<PaymentStep />} />
                    </Route>
                    <Route path="/final" element={<FinalStep />} />
                    <Route path="/end" element={<CloseSession />} />
                    <Route path="/activate" element={<ActivateStep />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
};


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LanguageProvider>
                <Router>
                    <Header />
                    <AnimatedRoutes />
                    <EmailModal />
                    <DeliveryMethodsAddressConfirm />
                    <SessionTimeout />
                    <Footer />
                </Router>
            </LanguageProvider>
        </Provider>
    </React.StrictMode>
);
