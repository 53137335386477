import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import checkpoint from "../../../../img/paymentStep/checkmark.svg";
import { navigateToPage } from "../../../../redux/slices/windowSlice";
import Localized from "../../../../Localized";

const DeliveryMethodItem = ({
    cardIcon,
    title,
    description,
    color,
    isSelected,
    onClick,
    addressRequired = false,
}) => {
    const dispatch = useAppDispatch();

    const { isDeliveryAddressComplete, deliveryAddress } = useAppSelector(
        (state: RootState) => state.orderSlice
    );

    const handleOpenAddressInput = () => {
        dispatch(navigateToPage("delivery_methods_address"));
    };

    return (
        <div
            className="delivery-methods_item"
            style={{ borderColor: isSelected ? color : "#ddd" }}
            onClick={onClick}>
            <div className="delivery-methods_item_wrapper">
                <div
                    className="delivery-methods_item_icon"
                    style={{ background: color ? color : "#fff" }}>
                    <img
                        src={cardIcon}
                        alt="Card"
                        className="delivery-methods_item_icon_img"
                    />
                </div>

                <div className="delivery-methods_item_info">
                    <span className="delivery-methods_item_title">{title}</span>
                    {description && (
                        <span className="delivery-methods_item_description">
                            {description}
                        </span>
                    )}

                    {isSelected &&
                        addressRequired &&
                        isDeliveryAddressComplete && (
                            <div
                                className="delivery-methods_item_info_address"
                                onClick={handleOpenAddressInput}>
                                <span
                                    className="delivery-methods_item_info_address_description"
                                    style={{
                                        color: color,
                                        textDecoration: "none",
                                    }}>
                                    {deliveryAddress.first_name}{" "}
                                    {deliveryAddress.last_name}{" "}
                                    {deliveryAddress.patronymic}
                                    <br />
                                    {deliveryAddress.postcode}{" "}
                                    {deliveryAddress.region && (
                                        <>
                                            {deliveryAddress.region}{" "}
                                            {Localized.DeliveryNotfoundRegion}{" "}
                                        </>
                                    )}
                                    {deliveryAddress.district && (
                                        <>
                                            {deliveryAddress.district}{" "}
                                            {Localized.DeliveryNotfoundDistrict}{" "}
                                        </>
                                    )}
                                    {deliveryAddress.city_type}{" "}
                                    {deliveryAddress.city},{" "}
                                    {deliveryAddress.street_type}{" "}
                                    {deliveryAddress.street},{" "}
                                    {deliveryAddress.building}{" "}
                                    {deliveryAddress.apartment}
                                </span>
                            </div>
                        )}

                    {isSelected && addressRequired && (
                        <div
                            className="delivery-methods_item_info_address"
                            onClick={handleOpenAddressInput}>
                            <span
                                className="delivery-methods_item_info_address_description"
                                style={
                                    isDeliveryAddressComplete
                                        ? {
                                              color: color,
                                          }
                                        : null
                                }>
                                {isDeliveryAddressComplete
                                    ? Localized.EditDeliveryAddress
                                    : Localized.EnterDeliveryAddess}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {isSelected && (
                <div
                    className="delivery-methods_item_checkmark"
                    style={{ background: color }}>
                    <img src={checkpoint} alt="checkmark" />
                </div>
            )}
        </div>
    );
};

export default DeliveryMethodItem;
