import { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useCloseSessionMutation } from "../../redux/api";
import { navigateToPage } from "../../redux/slices/windowSlice";

let countdownInterval;
let timeout;

const SessionTimeout = () => {
    const dispatch = useAppDispatch();
    const { stepCurrent, step } = useAppSelector((state) => state.windowSlice);
    const { token } = useAppSelector((state) => state.userSlice);
    const [closeSession] = useCloseSessionMutation();

    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimers = useRef(null);

    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };

    const handleLogout = async () => {
        try {
            setTimeoutModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
            closeSession()
                .unwrap()
                .then(() => {
                    dispatch(navigateToPage("end"));
                });
        } catch (err) {
            console.error(err);
        }
    };

    const handleContinue = () => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
    };

    const onActive = () => {
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };

    const onIdle = () => {
        const delay = 1000 * 1;
        if (!timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDown = 60;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout();
                    }
                }, 1000);
            }, delay);
        }
    };

    if (["final", "end"].includes(step) || !token) {
        return <></>;
    }

    return (
        <>
            <IdleTimer
                ref={idleTimers}
                onActive={onActive}
                onIdle={onIdle}
                debounce={250}
                timeout={120000}
            />
            <SessionTimeoutDialog
                countdown={timeoutCountdown}
                onContinue={handleContinue}
                onLogout={() => handleLogout()}
                open={timeoutModalOpen}
            />
        </>
    );
};

export default SessionTimeout;
