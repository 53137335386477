import LocalizedStrings from 'react-localization';
import ActivateStep from './components/Steps/ActivateStep';

let Localized = new LocalizedStrings({
    ru:{
      HeaderSelectTicket:"Выбор билетов",
      HeaderYourOrder:"Мои билеты",
      HeaderContact:"Контактные данные",
      HeaderFinal:"Завершение",
      HeaderPaymentMethods: "Способ оплаты",
      HeaderDeliveryMethods: "Формат билета",
      HeaderDeliveryMethodsAddress: "Адрес доставки",
      HeaderPaymentStep: "Оплата",
      HeaderEnd: "Завершение",
      HeaderTestMode: "Тестовый режим",
      HeaderActivate: "Активация билета",

      ItemAvailable:"Доступно",

      BtnAddToCartBtn:"Добавить",
      BtnUnavailableBtn:"Распродано",
      BtnLookOrder:"Добавить в корзину",
      BtnPlaceOrder:"Продолжить",
      BtnPayOrder:"Перейти к оплате",
      BtnOrderEmpty:"Корзина пуста",

      EnterDeliveryAddess: "Указать адрес доставки",
      EditDeliveryAddress: "Изменить",

      DeliveryAddressRecipient: "Кто получит письмо?",
      DeliveryAddressFirstname: "Имя",
      DeliveryAddressLastname: "Фамилия",
      DeliveryAddressPatronymic: "Отчество",
      DeliveryAddressPhoneNumber: "Номер телефона для связи",
      DeliveryAddressTitle: "На какой адрес нам доставить письмо?",
      DeliveryAddressMainInput: "Начните вводить адрес или почтовый код",
      DeliveryAddressCountry: "Страна",
      DeliveryAddressPostcode: "Почтовый индекс",
      DeliveryAddressCity: "Город",
      DeliveryAddressStreet: "Улица",
      DeliveryAddressHouse: "Дом",
      DeliveryAddressApartment: "Квартира",

      DeliveryNotfound: "Мы не смогли найти введённый адрес в базе данных Белпочты. Это не значит, что он неверен, возможно, произошла опечатка или не хватает какой-то детали. Пожалуйста, проверьте ещё раз.",
      DeliveryNotfoundPostcode: "индекс",
      DeliveryNotfoundRegion: "область",
      DeliveryNotfoundDistrict: "район",
      DeliveryNotfoundCity: "город",
      DeliveryNotfoundStreet: "улица",
      DeliveryNotfoundHouse: "дом",
      DeliveryNotfoundApartment: "квартира",
      DeliveryNotfoundChangeBtn: "Исправить",
      DeliveryNotfoundSubmitBtn: "Всё верно",

      SpanContactMailtext: "Приобретенные Вами билеты будут отправлены на электронный адрес:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Пожалуйста, убедитесь, что адрес электронной почты введен правильно.",
      CheckpointAgree: "Я согласен с правилами пользования сервисом.",

      SummaryTicket: "билет",
      Summary2Tickets: "билета",
      SummaryTickets: "билетов",
      SummarySum: "на сумму",
      SummaryService: "Работа сервиса",
      SummaryTotal: "Всего",

      FinalThankYou: "Спасибо за покупку!",
      FinalText: 'Билеты в формате PDF и для электронных кошельков, а также подтверждение заказа будут отправлены на адрес электронной почты {email}. Если письмо не поступит в течение 15 минут, проверьте папку "Спам". Если ситуация не разрешится – свяжитесь с нами.',
      FinalTextBelpost: "Ваш заказ успешно принят! В ближайшее время на указанный адрес {email} придет подробная информация. Если письмо не поступит в течение 15 минут, проверьте папку «Спам» или свяжитесь с нашей поддержкой.",
      FinalDownloadTicket: "Скачать PDF билет",
      FinalDownloadInvoice: "Скачать счёт",
      Finalneedmoretime: "Нам нужно чуть больше времени для генерации билетов.\nМы отправим их на почту!",

      SelectedText: "Выбрано",
      YouCanAddText: "Вы можете добавить к своему заказу",
      TimeLeft: "на покупку билетов",

      Soldout: "Билеты распроданы",
      SoldoutText: "Следите за новостями и обновлениями!",

      ToomanyrequestsTitle: "Внимание!",
      ToomanyrequestsText1: "На вашем соединении уже зарегистрирована активная сессия.",
      ToomanyrequestsText2: "Пожалуйста, закройте все вкладки сайта, отключите VPN и/или перейдите на личную сеть. Повторите попытку через несколько минут.",

      SomeTechnicalProblems: "Мы проводим техничекие работы, попробуйте зайти чуть позже. Спасибо за ожидание!",

      EmailModalText: "Введите адрес электронной почты, на который будут отправлены билеты",
      EmailModalDeliveryText: "Введите адрес электронной почты, на который мы отправим информацию по доставке",
      EmailModalPleactCorrect: "Введите корректный email.",
      EmailImportant: "<b>Важно!</b>\nПожалуйста, убедитесь, что адрес электронной почты введён правильно.",
      EmailCorrect: "Всё верно :)",
      EmailContinue: "Продолжить",
      EmailChange: "Изменить",
      EmailBack: "Назад", 

      ForPay: "К оплате",
      WillOpenPaymentStep: "Сейчас откроется окно оплаты",
      
      Agreement: "Даю согласие на обработку персональных данных.",
      Cancel: "Отменить", 

      ActivateEverythingOk: "Всё в порядке, билеты активированы, никаких дополнительных действий не требуется.",
      ActivateGoodbye: "До встречи на фестивале!",
      ActivateHaveaProblem: "Остались проблемы?",
      ActivateHaveaProblem2: "Есть проблема",

      ActivateTitle: "Перед тем как активировать билет, пожалуйста, внимательно проверь следующее:",
      ActivateStep1: "✅ Убедись, что конверт не поврежден и был открыт только тобой.",
      ActivateStep2: "✅ Сравни QR-код на билете с тем, что указан на экране. Они должны совпадать.",
      ActivateStep3: "✅ Проверь целостность самого билета: нет ли повреждений, разрывов, следов вскрытия или подделки.",

      ActivateTicket: "Билет:",
      ActivateCode: "QR-код:",
      ActivateSerial: "Серийный номер:",
      ActivateActivate: "Активировать"
    },
    pl:{
      HeaderSelectTicket:"Wybierz bilety",
      HeaderYourOrder:"Podsumowanie",
      HeaderContact:"Dane kontaktowe",
      HeaderFinal:"Koniec",
      HeaderPaymentMethods: "Metody płatności",
      HeaderDeliveryMethods: "Format biletu",
      HeaderDeliveryMethodsAddress: "Adres dostawy",
      HeaderPaymentStep: "Płatność",
      HeaderEnd: "Koniec",
      HeaderTestMode: "Tryb testowy",
      HeaderActivate: "Aktywacja biletu",

      ItemAvailable:"Dostępne",

      BtnAddToCartBtn:"Do koszyka",
      BtnUnavailableBtn:"Wyprzedane",
      BtnLookOrder:"Przejdź do podsumowania",
      BtnPlaceOrder:"Przejdź do potwierdzenia",
      BtnPayOrder:"Przejdź do płatności",
      BtnOrderEmpty:"Koszyk jest pusty",

      EnterDeliveryAddess: "Podaj adres dostawy",
      EditDeliveryAddress: "Edytuj",

      DeliveryAddressRecipient: "Kto otrzyma list?",
      DeliveryAddressFirstname: "Imię",
      DeliveryAddressLastname: "Nazwisko",
      DeliveryAddressPatronymic: "Drugie imię",
      DeliveryAddressPhoneNumber: "Numer telefonu do kontaktu",
      DeliveryAddressTitle: "Na jaki adres mamy wysłać list?",
      DeliveryAddressMainInput: "Zacznij wpisywać adres lub kod pocztowy",
      DeliveryAddressCountry: "Kraj",
      DeliveryAddressPostcode: "Kod pocztowy",
      DeliveryAddressCity: "Miasto",
      DeliveryAddressStreet: "Ulica",
      DeliveryAddressHouse: "Nr domu",
      DeliveryAddressApartment: "Nr mieszkania",

      DeliveryNotfound: "Nie znaleźliśmy podanego adresu w bazie danych Belpostu. To nie oznacza, że jest on nieprawidłowy, możliwe, że wystąpił błąd lub brakuje jakiegoś szczegółu. Proszę sprawdź jeszcze raz.",
      DeliveryNotfoundPostcode: "kod pocztowy",
      DeliveryNotfoundRegion: "region",
      DeliveryNotfoundDistrict: "dzielnica",
      DeliveryNotfoundCity: "miasto",
      DeliveryNotfoundStreet: "ulica",
      DeliveryNotfoundHouse: "nr domu",
      DeliveryNotfoundApartment: "nr mieszkania",
      DeliveryNotfoundChangeBtn: "Popraw",
      DeliveryNotfoundSubmitBtn: "Wszystko dobrze",

      SpanContactMailtext: "Po opłacie bilety zostaną wysłane na dany adres mailowy:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Potwierdzam prawidłowość wpisanych przeze mnie danych.",
      CheckpointAgree: "Zgadzam się z regułami korzystania serwisu.",

      SummaryTicket: "bilet", //cena 3 biletow
      Summary2Tickets: "bileta",
      SummaryTickets: "biletów",
      SummarySum: "na kwotę",
      SummaryService: "Opłata serwisowa",
      SummaryTotal: "Razem",

      FinalThankYou: "Dziękujemy za zakup!",
      FinalText: "Bilety i potwierdzenie zamówienia zostaną wysłane na adres e-mail {email}. Możesz je również pobrać w tym oknie.",
      FinalTextBelpost: "Twoje zamówienie zostało przyjęte! Wkrótce na podany adres {email} przyjdzie szczegółowa informacja. Jeśli wiadomość nie dotrze w ciągu 15 minut, sprawdź folder „Spam” lub skontaktuj się z naszym wsparciem.",
      Finalneedmoretime: "Potrzebujemy trochę więcej czasu na wygenerowanie biletów.\nWyślemy je na e-mail!",
      FinalDownloadTicket: "Pobierz bilet PDF",
      FinalDownloadInvoice: "Pobierz fakturę",

      SelectedText: "Wybrano:",
      YouCanAddText: "Możesz dodać do swojego zamówienia:",
      TimeLeft: "na zakup biletów",

      Soldout: "Bilety wyprzedane",
      SoldoutText: "Śledź nowości i aktualizacje!",

      ToomanyrequestsTitle: "Uwaga!",
      ToomanyrequestsText1: "Na Twoim połączeniu już zarejestrowana jest aktywna sesja.",
      ToomanyrequestsText2: "Proszę zamknąć wszystkie karty strony, wyłączyć VPN i/lub przełączyć się na sieć prywatną. Spróbuj ponownie za kilka minut.",

      SomeTechnicalProblems: "Przeprowadzamy prace techniczne, spróbuj wejść trochę później. Dziękujemy za cierpliwość!",

      EmailModalText: "Podaj adres e-mail, na który mają zostać wysłane bilety",
      EmailModalDeliveryText: "Podaj adres e-mail, na który wyślemy informacje o dostawie",
      EmailModalPleactCorrect: "Podaj poprawny adres e-mail.",
      EmailImportant: "<b>Uwaga!</b>\nUpewnij się, że podany adres e-mail jest poprawny.",
      EmailCorrect: "Wszystko dobrze :)",
      EmailContinue: "Kontynuuj",
      EmailChange: "Zmień",
      EmailBack: "Wróć",

      ForPay: "Do zapłaty",
      WillOpenPaymentStep: "Za chwilę otworzy się okno płatności",

      Agreement: "Wyrażam zgodę na przetwarzanie danych osobowych.",
      Cancel: "Anuluj",

      ActivateEverythingOk: "Wszystko w porządku, bilety zostały aktywowane, nie są wymagane dodatkowe działania.",
      ActivateGoodbye: "Do zobaczenia na festiwalu!",
      ActivateHaveaProblem: "Pozostały problemy?",
      ActivateHaveaProblem2: "Jest problem",
    
      ActivateTitle: "Przed aktywacją biletu proszę sprawdź dokładnie:",
      ActivateStep1: "✅ Upewnij się, że koperta nie jest uszkodzona i została otwarta tylko przez Ciebie.",
      ActivateStep2: "✅ Porównaj kod QR na bilecie z tym, który jest wyświetlany na ekranie. Powinny one być takie same.",
      ActivateStep3: "✅ Sprawdź integralność samego biletu: czy nie ma uszkodzeń, rozdarcia, śladów otwarcia lub fałszerstwa.",

      ActivateTicket: "Bilet:",
      ActivateCode: "Kod QR:",
      ActivateSerial: "Numer seryjny:",
      ActivateActivate: "Aktywuj"
    },
    en:{
      HeaderSelectTicket:"Ticket selection",
      HeaderYourOrder:"Your order",
      HeaderContact:"Contact details",
      HeaderFinal:"Final",
      HeaderPaymentMethods: "Payment methods",
      HeaderDeliveryMethods: "Ticket format",
      HeaderDeliveryMethodsAddress: "Delivery address",
      HeaderPaymentStep: "Payment",
      HeaderEnd: "End",
      HeaderTestMode: "Test mode",
      HeaderActivate: "Activate tickets",


      ItemAvailable:"Available",

      BtnAddToCartBtn:"Add to Cart",
      BtnUnavailableBtn:"Sold out",
      BtnLookOrder:"View the order",
      BtnPlaceOrder:"Place an order",
      BtnPayOrder:"Pay for the order",
      BtnOrderEmpty:"A cart is empty",

      EnterDeliveryAddess: "Enter delivery address",
      EditDeliveryAddress: "Edit",

      DeliveryAddressRecipient: "Who will receive the letter?",
      DeliveryAddressFirstname: "First name",
      DeliveryAddressLastname: "Last name",
      DeliveryAddressPatronymic: "Middle name",
      DeliveryAddressPhoneNumber: "Contact phone number",
      DeliveryAddressTitle: "Where should we send the letter?",
      DeliveryAddressMainInput: "Start entering an address or postal code",
      DeliveryAddressCountry: "Country",
      DeliveryAddressPostcode: "Postal code",
      DeliveryAddressCity: "City",
      DeliveryAddressStreet: "Street",
      DeliveryAddressHouse: "House",
      DeliveryAddressApartment: "Apartment",

      DeliveryNotfound: "We could not find the entered address in the Belpost database. This does not mean that it is incorrect, perhaps there was a typo or some detail is missing. Please check again.",
      DeliveryNotfoundPostcode: "postcode",
      DeliveryNotfoundRegion: "region",
      DeliveryNotfoundDistrict: "district",
      DeliveryNotfoundCity: "city",
      DeliveryNotfoundStreet: "street",
      DeliveryNotfoundHouse: "house",
      DeliveryNotfoundApartment: "apartment",
      DeliveryNotfoundChangeBtn: "Correct",
      DeliveryNotfoundSubmitBtn: "All right",

      SpanContactMailtext: "The tickets you have purchased will be sent to the email address:",
      SpanContactMailplaceholder: "Email",

      CheckpointCorrect: "I confirm the correctness of the data I entered.",
      CheckpointAgree: "I agree with the terms of use of the service.",

      SummaryTicket: "ticket",
      Summary2Tickets: "tickets",
      SummaryTickets: "tickets",
      SummarySum: "for the amount of",
      SummaryService: "Service fee",
      SummaryTotal: "Order amount",

      FinalThankYou: "Thank you for your purchase!",
      FinalText: "Tickets and order confirmation will be sent to the email address {email}. You can also download them in this window.",
      FinalTextBelpost: "Your order has been accepted! Soon detailed information will be sent to the specified address {email}. If the message does not arrive within 15 minutes, check the “Spam” folder or contact our support.",
      Finalneedmoretime: "We need a little more time to generate tickets.\nWe will send them to your email!",
      FinalDownloadTicket: "Download PDF ticket",
      FinalDownloadInvoice: "Download the invoice",

      SelectedText: "Selected:",
      YouCanAddText: "You can add to your order:",
      TimeLeft: "for the purchase",

      Soldout: "Tickets sold out",
      SoldoutText: "Follow the news and updates!",

      ToomanyrequestsTitle: "Attention!",
      ToomanyrequestsText1: "An active session is already registered on your connection.",
      ToomanyrequestsText2: "Please close all tabs of the site, turn off the VPN and/or switch to a private network. Try again in a few minutes.",

      SomeTechnicalProblems: "We are conducting technical work, try to enter a little later. Thank you for your patience!",

      EmailModalText: "Enter the email address to which the tickets will be sent",
      EmailModalDeliveryText: "Enter the email address to which we will send delivery information",
      EmailModalPleactCorrect: "Enter a valid email address.",
      EmailImportant: "<b>Important!</b>\nPlease make sure the email address is correct.",
      EmailCorrect: "All right :)",
      EmailContinue: "Continue",
      EmailChange: "Change",
      EmailBack: "Back",

      ForPay: "For payment",
      WillOpenPaymentStep: "The payment window will open soon",

      Agreement: "I agree to the processing of personal data.",
      Cancel: "Cancel",

      ActivateEverythingOk: "Everything is fine, the tickets are activated, no additional actions are required.",
      ActivateGoodbye: "See you at the festival!",
      ActivateHaveaProblem: "Problems remain?",

      ActivateTitle: "Before activating the ticket, please check carefully:",
      ActivateStep1: "✅ Make sure the envelope is not damaged and was opened only by you.",
      ActivateStep2: "✅ Compare the QR code on the ticket with the one displayed on the screen. They should match.",
      ActivateStep3: "✅ Check the integrity of the ticket itself: no damage, tears, signs of tampering or forgery.",

      ActivateTicket: "Ticket:",
      ActivateCode: "QR code:",
      ActivateSerial: "Serial number:",
      ActivateActivate: "Activate"

    },
    be:{
      HeaderSelectTicket:"Выбар квіткоў",
      HeaderYourOrder:"Ваша замова",
      HeaderContact:"Кантактныя дадзеныя",
      HeaderFinal:"Канец",
      HeaderPaymentMethods: "Метады аплаты",
      HeaderDeliveryMethods: "Фармат квітка",
      HeaderDeliveryMethodsAddress: "Адрас дастаўкі",
      HeaderPaymentStep: "Аплата",
      HeaderEnd: "Канец",
      HeaderTestMode: "Тэставы рэжым",
      HeaderActivate: "Актывацыя квітка",

      ItemAvailable:"Даступна",

      BtnAddToCartBtn:"У кошык",
      BtnUnavailableBtn:"Распрададзены",
      BtnLookOrder:"Паглядзець заказ",
      BtnPlaceOrder:"Аформіць заказ",
      BtnPayOrder:"Аплаціць заказ",
      BtnOrderEmpty:"Кошык пусты",

      EnterDeliveryAddess: "Указаць адрас дастаўкі",
      EditDeliveryAddress: "Змяніць",

      DeliveryAddressRecipient: "Хто атрымае ліст?",
      DeliveryAddressFirstname: "Імя",
      DeliveryAddressLastname: "Прозвішча",
      DeliveryAddressPatronymic: "Імя па бацьку",
      DeliveryAddressPhoneNumber: "Нумар тэлефона для зваротнай сувязі",
      DeliveryAddressTitle: "На які адрас нам даслаць ліст?",
      DeliveryAddressMainInput: "Пачніце ўводзіць адрас ці паштовы індэкс",
      DeliveryAddressCountry: "Страна",
      DeliveryAddressPostcode: "Почтовый индекс",
      DeliveryAddressCity: "Город",
      DeliveryAddressStreet: "Улица",
      DeliveryAddressHouse: "Дом",
      DeliveryAddressApartment: "Квартира",

      DeliveryNotfound: "Мы не змаглі знайсці уведзены адрас у базе дадзеных Белпошты. Гэта не значыць, што ён няправільны, магчыма, адбылася памылка ці не хапае якой-небудзь дэталі. Калі ласка, праверце яшчэ раз.",
      DeliveryNotfoundPostcode: "индекс",
      DeliveryNotfoundRegion: "область",
      DeliveryNotfoundDistrict: "район",
      DeliveryNotfoundCity: "город",
      DeliveryNotfoundStreet: "улица",
      DeliveryNotfoundHouse: "дом",
      DeliveryNotfoundApartment: "квартира",
      DeliveryNotfoundChangeBtn: "Папраўце",
      DeliveryNotfoundSubmitBtn: "Усё верна",

      SpanContactMailtext: "Набытыя Вамі квіткі будуць адпраўленыя на электронны адрас:",
      SpanContactMailplaceholder: "Email",

      CheckpointCorrect: "Пацвярджаю правільнасць уведзеных мною дадзеных.",
      CheckpointAgree: "Я згодны з правіламі карыстання сэрвісам.",

      SummaryTicket: "квіток",
      Summary2Tickets: "квіткі",
      SummaryTickets: "квіткоў",
      SummarySum: "на суму",
      SummaryService: "Праца сэрвісу",
      SummaryTotal: "Сума заказу",

      FinalThankYou: "Дзякуй за куплю!",
      FinalText: "Квіткі i пацвярджэнне замовы будуць адпраўленыя на адрас электроннай пошты {email}. Вы таксама можаце спампаваць іх у дадзеным акне.",
      FinalTextBelpost: "Ваш заказ прыняты! Незабаром на пазначаны адрас {email} прыйдзе падрабязная інфармацыя. Калі ліст не прыйдзе ў тэчэнне 15 хвілін, праверце тэчку «Спам» або звяжыцеся з нашай падтрымкай.",
      FinalDownloadTicket: "Спампаваць PDF білет",
      FinalDownloadInvoice: "Спампаваць рахунак",
      Finalneedmoretime: "Нам трэба цяпершні час для генерацыі білетаў.\nМы адправім іх на пошту!",

      SelectedText: "Выбраны:",
      YouCanAddText: "Вы можаце дадаць да вашага заказу:",
      TimeLeft: "на куплю квіткоў",

      Soldout: "Квіткі распрададзены",
      SoldoutText: "Сачыце за навінамі і абнаўленнямі!",

      ToomanyrequestsTitle: "Увага!",
      ToomanyrequestsText1: "На вашым злучэнні ўжо зарэгістраваная актыўная сесія.",
      ToomanyrequestsText2: "Калі ласка, зачыніце ўсе ўкладкі сайта, адключыце VPN і/або перайдзіце на асабістую сетку. Паўтарыце спробу праз некалькі хвілін.",

      SomeTechnicalProblems: "Мы праводзім тэхнічныя працы, паспрабуйце ўвайсці цікавей. Дзякуй за чаканне!",

      EmailModalText: "Увядзіце адрас электроннай пошты, на які будуць адпраўленыя білеты",
      EmailModalDeliveryText: "Увядзіце адрас электроннай пошты, на які мы адправім інфармацыю па дастаўцы",
      EmailModalPleactCorrect: "Увядзіце карэктны email.",
      EmailImportant: "<b>Увага!</b>\nКалі ласка, праверце, ці адрас электроннай пошты ўведзены правільна.",
      EmailCorrect: "Усё добра :)",
      EmailContinue: "Працягнуць",
      EmailChange: "Змяніць",
      EmailBack: "Назад",

      ForPay: "Да аплаты",
      WillOpenPaymentStep: "Зараз адкрыецца акно аплаты",

      Agreement: "Згоджуюся на апрацоўку асабістых дадзеных.",
      Cancel: "Адмяніць",

      ActivateEverythingOk: "Усё ў парадку, білеты актываваны, дадатковыя дзеянні не патрабуюцца.",
      ActivateGoodbye: "Да сустрэчы на фестывалі!",
      ActivateHaveaProblem: "Засталіся праблемы?",
      ActivateHaveaProblem2: "Ёсць праблема",

      ActivateTitle: "Перад актывацыяй білета праверце ўважліва:",
      ActivateStep1: "✅ Пераканайцеся, што канверт не пашкоджаны і быў адкрыты толькі вамі.",
      ActivateStep2: "✅ Параўнайце QR-код на білеце з тым, што паказаны на экране. Яны павінны супадаць.",
      ActivateStep3: "✅ Праверце цэласнасць самага білета: няма шкод, разарванняў, следаў ўзлому ці падману.",

      ActivateTicket: "Білет:",
      ActivateCode: "QR-код:",
      ActivateSerial: "Серыйны нумар:",
      ActivateActivate: "Актываваць"
    },
    uk:{
      HeaderSelectTicket:"Вибір квитків",
      HeaderYourOrder:"Ваше замовлення",
      HeaderContact:"Контактні дані",
      HeaderFinal:"Завершення",
      HeaderPaymentMethods: "Методи оплати",
      HeaderDeliveryMethods: "Формат квитка",
      HeaderDeliveryMethodsAddress: "Адреса доставки",
      HeaderPaymentStep: "Оплата",
      HeaderEnd: "Кінець",
      HeaderTestMode: "Тестовий режим",
      HeaderActivate: "Активація квитка",

      ItemAvailable:"Доступно",

      BtnAddToCartBtn:"Додати",
      BtnUnavailableBtn:"Розпродано",
      BtnLookOrder:"Переглянути замовлення",
      BtnPlaceOrder:"Оформити замовлення",
      BtnPayOrder:"Оплатити замовлення",
      BtnOrderEmpty:"Кошик порожній",

      EnterDeliveryAddess: "Вказати адресу доставки",
      EditDeliveryAddress: "Редагувати",

      DeliveryAddressRecipient: "Хто отримає листа?",
      DeliveryAddressFirstname: "Ім'я",
      DeliveryAddressLastname: "Прізвище",
      DeliveryAddressPatronymic: "По батькові",
      DeliveryAddressPhoneNumber: "Номер телефону для зв'язку",
      DeliveryAddressTitle: "На яку адресу нам доставити лист?",
      DeliveryAddressMainInput: "Почніть вводити адресу або поштовий індекс",
      DeliveryAddressCountry: "Країна",
      DeliveryAddressPostcode: "Поштовий індекс",
      DeliveryAddressCity: "Місто",
      DeliveryAddressStreet: "Вулиця",
      DeliveryAddressHouse: "Будинок",
      DeliveryAddressApartment: "Квартира",

      DeliveryNotfound: "Ми не змогли знайти введений адресу в базі даних Белпошти. Це не означає, що він неправильний, можливо, сталася помилка або не вистачає якоїсь деталі. Будь ласка, перевірте ще раз.",
      DeliveryNotfoundPostcode: "індекс",
      DeliveryNotfoundRegion: "область",
      DeliveryNotfoundDistrict: "район",
      DeliveryNotfoundCity: "місто",
      DeliveryNotfoundStreet: "вулиця",
      DeliveryNotfoundHouse: "будинок",
      DeliveryNotfoundApartment: "квартира",
      DeliveryNotfoundChangeBtn: "Виправити",
      DeliveryNotfoundSubmitBtn: "Все правильно",

      SpanContactMailtext: "Придбані Вами білети будуть відправлені на електронну адресу:",
      SpanContactMailplaceholder: "E-mail",

      CheckpointCorrect: "Підтверджую правильність моїх даних.",
      CheckpointAgree: "Я згоден з правилами користування сервісом.",

      SummaryTicket: "білет",
      Summary2Tickets: "білети",
      SummaryTickets: "білетів",
      SummarySum: "на суму",
      SummaryService: "Сервісний збір",
      SummaryTotal: "Сума замовлення",

      FinalThankYou: "Дякую за покупку!",
      FinalText: 'Білети і підтвердження замовлення будуть відправлені на адресу електронної пошти {email}. Ви також можете їх завантажити в цьому вікні.',
      FinalTextBelpost: "Ваше замовлення прийнято! Незабаром на вказану адресу {email} прийде докладна інформація. Якщо лист не прийде протягом 15 хвилин, перевірте папку «Спам» або зв’яжіться з нашою підтримкою.",
      Finalneedmoretime: "Нам потрібно трохи більше часу для генерації квитків.\nМи відправимо їх на пошту!",
      FinalDownloadTicket: "Завантажити білети",
      FinalDownloadInvoice: "Завантажити рахунок",

      SelectedText: "Обрано:",
      YouCanAddText: "Ви можете додати до свого замовлення:",
      TimeLeft: "на покупку квитків",

      Soldout: "Квитки розпродано",
      SoldoutText: "Слідкуйте за новинами та оновленнями!",

      ToomanyrequestsTitle: "Увага!",
      ToomanyrequestsText1: "На вашому з'єднанні вже зареєстрована активна сесія.",
      ToomanyrequestsText2: "Будь ласка, закрийте всі вкладки сайту, вимкніть VPN і/або перейдіть на особисту мережу. Повторіть спробу через кілька хвилин.",

      SomeTechnicalProblems: "Ми проводимо технічні роботи, спробуйте зайти трохи пізніше. Дякуємо за терпіння!",

      EmailModalText: "Введіть адресу електронної пошти, на яку будуть відправлені квитки",
      EmailModalDeliveryText: "Введіть адресу електронної пошти, на яку ми відправимо інформацію про доставку",
      EmailModalPleactCorrect: "Введіть коректний email.",
      EmailImportant: "<b>Важливо!</b>\nБудь ласка, переконайтеся, що адреса електронної пошти введена правильно.",
      EmailCorrect: "Все добре :)",
      EmailContinue: "Продовжити",
      EmailChange: "Змінити",
      EmailBack: "Назад",

      ForPay: "До оплати",
      WillOpenPaymentStep: "Зараз відкриється вікно оплати",

      Agreement: "Даю згоду на обробку персональних данних.",
      Cancel: "Скасувати",

      ActivateEverythingOk: "Все в порядку, квитки активовані, додаткових дій не потрібно.",
      ActivateGoodbye: "До зустрічі на фестивалі!",
      ActivateHaveaProblem: "Залишилися проблеми?",
      ActivateHaveaProblem2: "Є проблема",

      ActivateTitle: "Перед активацією квитка уважно перевірте:",
      ActivateStep1: "✅ Переконайтеся, що конверт не пошкоджений і був відкритий тільки вами.",
      ActivateStep2: "✅ Порівняйте QR-код на квитку з тим, що відображений на екрані. Вони повинні співпадати.",
      ActivateStep3: "✅ Перевірте цілісність самого квитка: немає пошкоджень, розривів, слідів відкриття або підробки.",

      ActivateTicket: "Квиток:",
      ActivateCode: "QR-код:",
      ActivateSerial: "Серійний номер:",
      ActivateActivate: "Активувати"
    },
});

export default Localized;